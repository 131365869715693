import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';

/*
 * Starting in Angular 8 syntax for lazy loading switched to:
 * loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
 * https://angular.io/guide/lazy-loading-ngmodules
 */

/* tslint:disable:max-line-length */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomepageComponent,
    data: {
      description: "Premier online coding classes for ages 5-18. Our coding camps offer the most comprehensive curriculum, live online instruction and inspiring instructors. With over 50,000 alumni, we provide classes in Python, web development, C#, and more.",
      keywords: 'Summer STEM camps, Live Online Coding Camps, Coding Classes, Coding Camps Near Me, Virtual Camps',
      tab: 'HOME',
      title: 'Premier Online Coding Classes for Kids and Teens - Coding With Kids'
    }
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    data: {
      robots: 'none',
      tab: 'ACCOUNT',
      title: 'Account'
    }
  },
  {
    path: 'alert',
    loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule),
    data: {
      robots: 'none',
      tab: 'ACCOUNT',
      title: 'Alert'
    }
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
    data: {
      description: 'Coding with Kids blog',
      keywords: 'Blog, Coding with Kids blog, news, updates',
      mode: 'BLOG',
      tab: 'ABOUT-US',
      title: 'Blog'
    }
  },
  /*{
    path: 'camps',
    loadChildren: () => import('./camp-single-location/camp-single-location.module').then(m => m.CampSingleLocationModule),
    data: {
      tab: 'REGISTRATIONS',
      title: 'Camps'
    }
  },*/
  /*{
    path: 'classes-for-adults',
    loadChildren: () => import('./adult-classes/adult-classes.module').then(m => m.AdultClassesModule),
    data: {
      description: 'Virtual coding classes for adults. Once-a-week live sessions with a tech star instructor! Small groups. Scratch, Python, Web Development. Learn how to code e-cards with your child, find and process data, create your own website, and more!',
      keywords: 'coding classes, virtual coding classes, coding, computer programming, classes for adults, technology, tech, beginner coding course, Python, web development',
      tab: 'REGISTRATIONS',
      title: 'Online Coding Classes for Adults'
    }
  },*/
  {
    path: 'coders-ladder',
    loadChildren: () => import('./coders-ladder/coders-ladder.module').then(m => m.CodersLadderModule),
    data: {
      description: "Explore Coder’s Ladder® by Coding With Kids. Our premium coding classes feature a multi-year progressive curriculum, small class sizes, individualized approaches, and more.",
      keywords: 'Premium Coding Classes',
      tab: 'ABOUT-US',
      title: "Coder’s Ladder® | Premium Coding Classes - Coding With Kids"
    }
  },
  {
    path: 'coders-ladder-group-classes',
    loadChildren: () => import('./online-coders-ladder/online-coders-ladder.module').then(m => m.OnlineCodersLadderModule),
    data: {
      description: "Climb the coding ladder with Coding With Kids' Online Coder’s Ladder® Group Coding Classes for kids. Our curriculum builds coding fundamentals to computer science mastery.",
      keywords: 'Coding Classes For Kids',
      tab: 'REGISTRATIONS',
      title: "Online Coder’s Ladder® | Group Coding Classes For Kids - Coding With Kids"
    }
  },
  {
    path: 'coders-pathways',
    loadChildren: () => import('./coders-pathways/coders-pathways.module').then(m => m.CodersPathwaysModule),
    data: {
      description: "Coder's Pathways® offers personalized progression based on age, experience, and interests. Master multiple pathways concurrently, like Python and Minecraft Modding.",
      keywords: 'Best Coding Class',
      tab: 'ABOUT-US',
      title: "Coder’s Pathways® | Best Coding Classes - Coding With Kids"
    }
  },
  {
    path: 'coding-programs-for-schools',
    loadChildren: () => import('./integrated-coding-programs/integrated-coding-programs.module').then(m => m.IntegratedCodingProgramsModule),
    data: {
      description: 'Looking to bring coding enrichment programs to your school? Coding with Kids offers high quality programs in a variety of formats and with minimal overhead for you. Loved by 50,000+ students and 600+ school partners.',
      keywords: 'enrichment classes, coding classes, online coding classes, virtual coding classes, coding, coding for kids, computer programming, education programs, school, after school, classes, technology, electives, enrichment programs, after-school coordinators, afterschool programs',
      tab: 'SCHOOLS',
      title: 'Coding Programs for Schools',
    }
  },
  {
    path: 'confirmations',
    loadChildren: () => import('./confirmations/confirmations.module').then(m => m.ConfirmationsModule),
    data: {
      robots: 'none',
      title: 'Confirmations'
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    data: {
      description: 'Question, comments, or suggestions? Our goal is to respond to all inquiries within 24 hours.',
      keywords: 'Coding with Kids, contact, help, questions, urgent',
      tab: 'ABOUT-US',
      title: 'Contact us'
    }
  },
  {
    path: 'create-account',
    loadChildren: () => import('./create-account/create-account.module').then(m => m.CreateAccountModule),
    data: {
      robots: 'none',
      title: 'Create Account'
    }
  },
  {
    path: 'customer-feedback',
    loadChildren: () => import('./customer-feedback/customer-feedback.module').then(m => m.CustomerFeedbackModule),
    data: {
      robots: 'none',
      title: 'Customer Feedback'
    }
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule),
    data: {
      description: 'Frequently asked questions and cancellation policies.',
      keywords: 'FAQs, questions, logistics, technical, programs, Coder\'s Ladder® classes',
      tab: 'ABOUT-US',
      title: 'FAQs'
    }
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
    data: {
      description: 'Enhance education with Coding With Kids coding programs for schools! Trusted by 36,000+ students and 800+ schools, offering high-quality coding enrichment with minimal overhead.',
      keywords: 'Coding Programs for Schools, Coding for schools',
      tab: 'ABOUT-US',
      title: 'Coding Programs for Schools | Coding For Schools - Coding With Kids'
    }
  },
  {
    path: 'in-person-camps',
    loadChildren: () => import('./in-person-camps/in-person-camps.module').then(m => m.InPersonCampsModule),
    data: {
      description: 'Learn. Create. Code! In-person coding camps for kids include Roblox, Minecraft, Scratch, Python, C#, Java & more for students aged 5-18! We cater to all skill levels!',
      keywords: 'In-person Coding Camps For Kids',
      tab: 'REGISTRATIONS',
      title: 'In-person Camps | In-person Coding Camps For Kids - Coding With Kids'
    }
  },
  {
    path: 'in-person-enrichment-classes',
    loadChildren: () => import('./in-person-enrichment/in-person-enrichment.module').then(m => m.InPersonEnrichmentModule),
    data: {
      description: 'After-school In-person Enrichment Classes for ages 5-18. Weekly sessions, small class sizes. 40+ engaging LIVE programs. Roblox, Minecraft, Scratch, Python, C#, Java & more!',
      keywords: 'In-person Enrichment Classes',
      tab: 'REGISTRATIONS',
      title: 'In-person Enrichment Classes - Coding With Kids',
    }
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
    data: {
      description: 'Apply today to join the Coding with Kids team! We are looking for smart, self-driven instructors, managers, and program coordinators to join our team and help us build and deliver great computer science education program for kids.',
      keywords: 'Jobs, instructor, manager, coding instructor, part-time, full-time',
      tab: 'ABOUT-US',
      title: 'Jobs'
    }
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    data: {
      description: 'Meet the Coding with Kids management team!',
      keywords: 'Coding with Kids, management team, founder, co-founder, directors, managers',
      tab: 'ABOUT-US',
      title: 'Management Team'
    }
  },
  {
    path: 'online-camps',
    loadChildren: () => import('./online-camps/online-camps.module').then(m => m.OnlineCampsModule),
    data: {
      description: 'Explore Coding With Kids online camps! 40+ engaging LIVE online coding camps for kids aged 5-18. We offer Roblox, Minecraft, Python, Java & more and we cater to all skill levels!',
      keywords: 'Coding camps, Online Coding camps, Coding camps for kids',
      tab: 'REGISTRATIONS',
      title: 'Online Camps | Online Coding Camps For Kids - Coding With Kids'
    }
  },
  /*{
    path: 'online-enrichment-classes',
    loadChildren: () => import('./online-enrichment/online-enrichment.module').then(m => m.OnlineEnrichmentModule),
    data: {
      description: 'Join our after-school Online Enrichment Classes for ages 5-18. Weekly sessions, small groups, 40+ engaging LIVE programs. Explore Roblox, Minecraft, Scratch, Python, and more!',
      keywords: 'Online Enrichment Classes',
      tab: 'REGISTRATIONS',
      title: 'Online Enrichment Classes - Coding With Kids'
    }
  },*/
  {
    path: 'our-students',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
    data: {
      description: 'Coding with Kids student spotlights!',
      keywords: 'Superstar spotlight, Coding with Kids superstars, super coders, student spotlights, Our students',
      mode: 'OUR-STUDENTS',
      tab: 'ABOUT-US',
      title: 'Our Students'
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    data: {
      description: 'Coding with Kids privacy policy.',
      keywords: 'Coding with Kids, privacy policy',
      tab: 'ABOUT-US',
      title: 'Privacy Policy'
    }
  },
  {
    path: 'private-classes',
    loadChildren: () => import('./private-classes/private-classes.module').then(m => m.PrivateClassesModule),
    data: {
      description: "Boost learning with Coding With Kids' Online Private Coding Classes. Kids ages 6-18 excel in personalized 1:1 lessons led by experts. Master coding at your pace, from Scratch to Java!",
      keywords: 'Online Private Coding Classes',
      tab: 'REGISTRATIONS',
      title: "Online Private Coding Classes | Private 1:1 Classes - Coding With Kids"
    }
  },
  {
    path: 'region/:urlIdentifier',
    loadChildren: () => import('./region/region.module').then(m => m.RegionModule),
    data: {
      description: 'Premier coding classes & camps for ages 5-18. The most comprehensive curriculum and perfected live online delivery. Inspiring instructors. Over 50,000 alumni. Classes include Python, Video games development, Minecraft modding, Web development, C#, and more.',
      keywords: 'Summer STEM camps, Live Online Coding Camps, Coding Classes, Coding Camps Near Me, Virtual Camps'
    }
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
    data: {
      robots: 'none',
      tab: 'ACCOUNT',
      title: 'Reset Password'
    }
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
    data: {
      tab: 'ABOUT-US',
      title: 'Terms of Service'
    }
  },
  {
    path: 'verify-email/:key',
    loadChildren: () => import('./verify-email/verify-email.module').then(m => m.VerifyEmailModule),
    data: {
      robots: 'none',
      title: 'Verify Email'
    }
  },
  {
    path: 'video-consent',
    loadChildren: () => import('./video-consent/video-consent.module').then(m => m.VideoConsentModule),
    data: {
      robots: 'none',
      title: 'Video Consent'
    }
  },
  {
    path: '**',
    redirectTo: ''
  }
];
/* tslint:enable:max-line-length */

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
